// require('./bootstrap')

// import { createApp } from 'vue'
import { createApp } from 'vue/dist/vue.esm-bundler';
import Notes from './components/Notes.vue';

// window.Vue.config.productionTip = false

const app = createApp({})

app.component('notes', Notes)

app.mount('#vue')