<template>
    <div class="card notes">
        <div class="card-body">
            <div class="notes-header">
                <img src="/image/lock-icon.png" alt="notes">
                <span><strong>Notes</strong></span>
            </div>
            <div class="mt-3">
                <div class="form-group">
                    <textarea name="" id="" class="form-control" rows="4" v-model="note"></textarea>
                    <span class="text-danger" v-if="errors.note">{{ errors.note[0] }}</span>
                </div>
                
                <button class="btn btn-secondary mt-1" @click="addNote">Save Note</button>
            </div>

            <hr>

            <div class="list">
                <div class="details mb-3" v-for="note in notes" :key="note.id">
                   <div >
                    <div class="date">
                        <p>{{ moment.utc(note.created_at, 'YYYY-MM-DD H:mm:ss').local().format('MMM DD, h:mm A') }}</p>
                        <p>{{ note.created_by ? note.created_by.first_name : '' }} {{ note.created_by ? note.created_by.last_name : '' }}</p>
                    </div>
                    <div class="email">
                        <p>{{ note.created_by ? note.created_by.email : '' }}</p>
                    </div>
                    <div>
                        <p style="white-space: pre-wrap">{{ note.note }}</p>
                    </div>
                   </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { ref } from '@vue/reactivity'
import axios from 'axios'
import { onMounted } from '@vue/runtime-core'
import moment from 'moment'
import {useToast} from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';

export default {
    props: {
        user_id: null
    },
    data() {
        return {
            moment: moment
        }
    },
    setup(props) {
        const notes = ref('')
        const note = ref('')
        const errors = ref({})
        const $toast = useToast();

        const addNote = async() => {
            errors.value = {};

            await axios.post('/api/notes', {
                user_id: props.user_id,
                note: note.value
            })
            .then(res => {
                note.value = ''
                const data = res.data.note
                notes.value.unshift(data)

                $toast.open({
                    message: res.data.success,
                    type: 'success',
                    position: 'top-right'
                });
            })
            .catch(error => {
                errors.value = error.response.data.errors

                $toast.open({
                    message: error.response.data.errors.note[0],
                    type: 'error',
                    position: 'top-right'
                });
            });
        }

        const getNotes = async() => {
            const res = await axios.get('/api/notes/list', {
                params: {
                    user_id: props.user_id
                }
            });
            notes.value = res.data.notes;
        }

        onMounted(() => {
            getNotes();
            
        })

        return {
            notes,
            note,
            errors,
            addNote
        }
    }
}
</script>

<style>
.notes-header {
    display: flex;
}
.notes-header img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}
.details {
    background: #f8fafc;
    border-radius: 5px;
    padding: 10px;
}
p {
    margin-bottom: 0;
}
.notes .date {
    display: flex;
    justify-content: space-between;
}
.notes .email {
    text-align: right;
}
</style>